import * as React from 'react';
import PropTypes from 'prop-types';
import { isValidElementType } from 'react-is';
import { useHistory, useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import classnames from 'classnames';
import { singular } from 'pluralize';

import Box from './Box';
import { Modal } from './Modal';
import { Btn, BtnAdd } from './Btn';
import Table from './Table';
import { xhrHelper } from '../modules/Helper';
import { usePropState } from '../modules/Hooks';

const defaultGetDisplayName = getId => row => `#${getId(row)}`;

const List = ({
  edit: enableEdit,
  editButton = true,
  add: enableAdd,
  delete: enableDelete,
  deleteButton = true,
  loading: externalLoading,
  numActionsPerRow,
  model,
  beforeActions,
  afterActions,
  columns,
  title = model.title,
  filters: Filters,
  editForm: EditForm,
  modalSize,
  tabs = [],
  defaultTabId,
  getId = model.getId ? model.getId.bind(model) : row => row.id,
  getRowClassName,
  getDisplayName = model.getDisplayName
    ? model.getDisplayName.bind(model)
    : defaultGetDisplayName(getId),
}) => {
  const [data, setData] = React.useState(null);
  const [editData, setEditData] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [fetchRequest, setFetchRequest] = React.useState(null);
  const [activeTabId, setActiveTabId] = usePropState(defaultTabId);
  const history = useHistory();
  const location = useLocation();

  const searchParams = React.useMemo(
    () => ({
      ...((Filters && Filters.defaultValues) || {}),
      ...Object.fromEntries(new URLSearchParams(location.search).entries()),
    }),
    [Filters, location.search],
  );

  const [dataTableSearch, setDataTableSearch] = usePropState(
    searchParams['data-table-search'] || '',
  );

  const fetchData = React.useCallback(
    ({ preserveData = false } = {}) => {
      if (!preserveData) setData(null);

      const xhr = searchParams.id
        ? model.get(searchParams.id)
        : model.list(searchParams);

      xhr
        .then(resp => {
          const newData = Array.isArray(resp) ? resp : [resp];
          setData(newData);
          return newData;
        })
        .catch(() => {
          if (xhr.readyState !== XMLHttpRequest.UNSENT) {
            setData([]);
          }
        });

      setFetchRequest(xhr);

      return xhr;
    },
    [model, searchParams],
  );

  React.useEffect(() => {
    if (fetchRequest && fetchRequest.abort) {
      return () => fetchRequest.abort();
    }
  }, [fetchRequest]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const searchParamsId = getId(searchParams);

  React.useEffect(() => {
    if (enableEdit) {
      const { pathname, hash } = location;

      if (searchParamsId && searchParams.auto_click === 'edit') {
        model.get(searchParamsId).then(setEditData);
      } else if (searchParams.auto_click === 'new') {
        const editDataFromParams = omit(searchParams, ['auto_click']);

        let url = [pathname, new URLSearchParams(editDataFromParams)].join('?');
        if (hash) url += hash;

        setEditData(omit(editDataFromParams, ['data-table-search']));
        history.replace(url);
      }
    }
  }, [history, location, enableEdit, model, searchParams, searchParamsId]);

  const afterSave = async id => {
    const newData = (await fetchData({ preserveData: true })) || [];

    setEditData(
      current => (current && newData.find(item => getId(item) === id)) || null,
    );
  };

  const activeTab =
    (activeTabId && tabs.find(tab => activeTabId === tab.id)) || tabs[0];

  const tabData = React.useMemo(
    () =>
      Object.fromEntries(
        tabs.map(tab => [tab.id, data && data.filter(tab.filter)]),
      ),
    [data, tabs],
  );

  const disabledButtons = Boolean(editData || loading);

  const editRow = row => setEditData(row);
  const deleteRow = async row => {
    const id = getId(row);
    const restore = model.restore && row.is_hidden;

    if (window.confirm(`${restore ? 'Restore' : 'Delete'} ${title} #${id}?`)) {
      try {
        await xhrHelper(model[restore ? 'restore' : 'delete'](id));
        if (restore) {
          return { refreshData: true };
        } else {
          setData(d => d && d.filter(item => getId(item) !== id));
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const actions = [
    ...(beforeActions || []),
    enableEdit &&
      editButton &&
      (({ row }) => ({
        id: 'edit',
        type: 'primary',
        title: 'Edit item',
        icon: 'fa-edit',
        onClick: () => editRow(row),
      })),
    enableDelete &&
      deleteButton &&
      (({ row }) => {
        const restore = model.restore && row.is_hidden;

        return {
          id: 'delete',
          type: restore ? 'success' : 'danger',
          title: restore ? 'Restore' : 'Delete',
          icon: restore ? 'fa-undo' : 'fa-trash-o',
          onClick: () => deleteRow(row),
        };
      }),
    ...(afterActions || []),
  ].filter(Boolean);

  const extraColumns = [];

  if (actions.length) {
    const width = numActionsPerRow
      ? numActionsPerRow * 50
      : actions.length > 1
      ? 100
      : 50;

    const actionClickHandler = (id, actionType, onClick) =>
      onClick &&
      (async () => {
        setLoading({ [actionType]: id });
        const { refreshData } = (await onClick({ editRow, deleteRow })) || {};
        if (refreshData) await fetchData({ preserveData: true });
        setLoading(null);
      });

    const renderAction = (
      id,
      { id: actionType, onClick, icon, disabled, menuItems, ...props },
    ) => (
      <Btn
        {...props}
        key={actionType}
        onClick={actionClickHandler(id, actionType, onClick)}
        disabled={disabled || disabledButtons || loading}
        loading={loading && loading[actionType] === id}
      >
        {typeof icon === 'string' ? (
          <i className={classnames('fa', icon)} />
        ) : (
          icon
        )}
      </Btn>
    );

    const renderMenuItem = id => ({
      title: actionTitle,
      onClick: onActionClick,
      linkTo,
      ...action
    }) => (
      <li
        key={action.id}
        onClick={
          linkTo
            ? () => history.push(linkTo)
            : actionClickHandler(id, action.id, onActionClick)
        }
      >
        {renderAction(id, action)}
        {actionTitle}
      </li>
    );

    extraColumns.push({
      key: 'action',
      headerStyle: {
        width,
        minWidth: width,
      },
      cellRenderer: cellProps =>
        actions.map(actionGetter => {
          const action = actionGetter(cellProps, data);
          if (!action) return null;

          const id = getId(cellProps.row);
          const rendered = renderAction(id, action);

          return action.menuItems && action.menuItems.length ? (
            <div key={action.id} className="action-container">
              {rendered}
              <ul className="action-menu active">
                {action.menuItems.map(renderMenuItem(id))}
              </ul>
            </div>
          ) : (
            rendered
          );
        }),
    });
  }

  return (
    <Box
      title={title}
      rightBox={
        enableAdd ? (
          <BtnAdd
            onClick={() =>
              setEditData(omit(searchParams, ['data-table-search']))
            }
          />
        ) : null
      }
      subheader={
        Filters && (
          <Filters tableData={data} dataTableSearch={dataTableSearch} />
        )
      }
    >
      {tabs.length > 0 && (
        <ul className="nav nav-tabs">
          {tabs.map(tab => (
            <li
              key={tab.id}
              className={classnames({ active: activeTab.id === tab.id })}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setActiveTabId(tab.id);
                }}
              >
                {tab.title}
                {tabData[tab.id] && tabData[tab.id].length
                  ? ` (${tabData[tab.id].length})`
                  : ''}
              </a>
            </li>
          ))}
        </ul>
      )}
      <Table
        loading={externalLoading || !data}
        data={activeTab && tabData[activeTab.id] ? tabData[activeTab.id] : data}
        columns={[...columns, ...extraColumns]}
        getRowKey={getId}
        getRowClassName={getRowClassName}
        filter={dataTableSearch}
        setFilter={setDataTableSearch}
      />
      {EditForm && (
        <Modal
          title={
            editData && getId(editData)
              ? `Edit ${singular(title)} ${getDisplayName(editData)}`
              : `Add ${singular(title)}`
          }
          size={modalSize}
          open={Boolean(editData)}
          afterClose={() => setEditData(null)}
        >
          {editData && (
            <EditForm
              data={editData}
              onSubmit={saveData => {
                const id = getId(editData);
                if (!id && !enableAdd) {
                  throw new Error(
                    'Form data supplied without ID, but `add` functionality is not enabled',
                  );
                } else if (id && !enableEdit) {
                  throw new Error(
                    'Form data supplied with ID, but `edit` functionality is not enabled',
                  );
                }

                return model[id ? 'update' : 'add'](saveData).then(
                  (response, status, xhr) => {
                    let newId =
                      id ||
                      getId(saveData) ||
                      (xhr && xhr.getResponseHeader('X-TBMS-New-ID')) ||
                      (response && response.id);

                    newId = Number(newId) || newId;

                    if (
                      response &&
                      response.errors &&
                      Object.keys(response.errors).length
                    ) {
                      return response.errors;
                    }

                    return afterSave(newId);
                  },
                );
              }}
              onSubmitSuccess={afterSave}
            />
          )}
        </Modal>
      )}
    </Box>
  );
};

export default List;

const elementPropType = (options = {}) => (props, propName, component) => {
  if (
    (options.required === true ||
      (typeof options.required === 'function' &&
        options.required(props, propName, component))) &&
    !props[propName]
  ) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${component}': the prop is required`,
    );
  }

  if (props[propName] && !isValidElementType(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${component}': the prop is not a valid React component`,
    );
  }
};

List.propTypes = {
  // required
  model: PropTypes.shape({
    title: PropTypes.string.isRequired,
    add: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    list: PropTypes.func.isRequired,
    getId: PropTypes.func,
  }).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      filter: PropTypes.func,
    }),
  ),
  columns: Table.propTypes.columns,

  editForm: elementPropType({
    required: props => Boolean(props.edit || props.add),
  }),

  // optional
  loading: PropTypes.bool,
  title: PropTypes.string,
  add: PropTypes.bool,
  edit: PropTypes.bool,
  editButton: PropTypes.bool,
  delete: PropTypes.bool,
  deleteButton: PropTypes.bool,
  modalSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  filters: elementPropType(),
  getId: PropTypes.func, // row => string

  beforeActions: PropTypes.arrayOf(PropTypes.func),
  afterActions: PropTypes.arrayOf(PropTypes.func),
};

List.defaultProps = {
  add: false,
  edit: false,
  delete: false,
  modalSize: 'md',
};
