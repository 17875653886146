import * as React from 'react';

import { EditForm, Field, validators } from '../../components/FinalForm';
import { options as priorityOptions } from '../../components/BikeCheckinPriority';

const bikeToOption = bike => ({
  value: bike.id,
  label: bike.numberplate,
});

export default props => (
  <EditForm {...props}>
    <Field
      className="col-sm-12"
      name="bike_id"
      validate={validators.required}
      select={{
        endpoint: '/bike/list',
        toOption: bikeToOption,
        isClearable: true,
      }}
    />
    <Field
      className="col-sm-12"
      name="location_id"
      validate={validators.required}
      select={{
        endpoint: '/location/list',
        isClearable: true,
      }}
    />
    <Field
      className="col-sm-12"
      name="odometer_x10"
      label="End of Contract Odometer (km)"
      validate={validators.required}
    />
    <Field
      className="col-sm-12"
      name="notes_office"
      label="Notes"
      rows={3}
      type="textarea"
    />
    <Field
      className="col-sm-12"
      label="Ready for hire?"
      name="ready_for_hire"
      type="checkbox"
    />
    <Field
      className="col-sm-12"
      label="Priority"
      name="priority_numeric"
      select={{ options: priorityOptions }}
      defaultValue={3}
    />
  </EditForm>
);
